$hyoo_fallacy $mol_book2
	title \Некорректные доводы
	plugins /
		<= Theme $mol_theme_auto
		<= Hotkey $mol_hotkey
			mod_ctrl true
			key *
				F?event <=> search_focus?event null
	pages /
		<= Menu $mol_page
			title \Типы софизмов
			tools /
				<= Lights $mol_lights_toggle
				<= Sources $mol_link_source
					uri \https://github.com/hyoo-ru/fallacy.hyoo.ru
			body /
				<= Filters $mol_list
					rows <= filters /
						<= All $mol_link
							arg * filter null
							title \Все
						<= Selected $mol_link
							arg * filter \selected
							title \📌Отобранное
			foot /
				<= Feedback $mol_link
					uri \https://github.com/hyoo-ru/fallacy.hyoo.ru/issues
					title \Ошибки? Правки? Предложения?
	Content* $mol_page
		title <= content_title* \
		tools /
			<= Search $mol_search
				query?val <=> search?val \
		body /
			<= Cards* $mol_list
				rows <= cards* /
				Empty <= Cards_empty $mol_view
					sub /
						<= empty_label \Заблуждений не найдено
	Filter* $mol_link
		arg <= filter_arg* *
		title <= filter_title* \
	Card* $hyoo_fallacy_card
		tags <= tags
		selected?val <=> fallacy_selected*?val false
		fallacy <= fallacy* *
			tags /string
			title \
			descr \
			sample \
		highlight <= search
	tags *$hyoo_fallacy_tag
		person * title \🤺 Атака через личность
		emotion * title \🎭 Эксплуатация эмоций
		logic * title \🧠 Нарушение логики
		content * title \📊 Манипуляция контентом
		reference * title \🎅 Сомнительная ссылка
	fallacies *$hyoo_fallacy_data
		- zero *
			tags /
			title \🚫 Зеро
			descr \Аргумент, не содержащий софизмов.
			sample \Вымирание динозавров из-за атаки пришельцев маловероятно, так как свидетельства в пользу других гипотез о причинах вымирания значительно сильнее и лучше подтверждены.
		force *
			tags / \person
			title \💪 Обращение к силе
			descr \Угрожать оппоненту физической расправой.
			sample \Наша страна должна вооружиться так, чтобы нас боялись, и тогда мир признает, что мы правы!
		ridicule *
			tags / \person
			title \🤣 Высмеивание
			descr \Высмеивать аргумент или человека без опровержения самого аргумента.
			sample \Вы отрицаете очевидное, чтобы попрактиковаться в демагогии? Хватит клоунады, мы тут о серьёзных вещах говорим.
		dummy *
			tags / \content
			title \🥊 Соломенное чучело
			descr \Перевирать доводы оппонента для того, чтобы было легче нападать.
			sample \Олег поддержал увеличение дотаций на образование и здравоохранение. Павел возмутился: "Не думал, что ты ненавидишь свою страну настолько, что хочешь оставить её беззащитной, урезав расходы на армию".
		social *
			tags / \person
			title \🥶 Аргумент к социальной неуспешности
			descr \Атаковать оппонента за то, что он не имеет социального статуса, который, по мнению атакующего, даёт этому оппоненту какое-либо право иметь критикуемую позицию.
			sample \Как ты можешь что-либо доказывать, если у тебя нет ни жены, ни детей? Ты до сих пор ещё с родителями живёшь!
		yourself *
			tags / \person
			title \🤳 А сам какой?!
			descr \Указывать на то, что оппонент сам действует вопреки аргументу.
			sample \Вася: Курение вредит здоровью, людям не следует курить. Петя: Но ты ведь сам курил пару минут назад. Значит, курить можно.
		personality *
			tags / \person
			title \👇 Переход на личности
			descr \Обращать внимание на личные качества или характер оппонента для того, чтобы победить в споре.
			sample \Ты утверждаешь, что безбожники - порядочные люди. Но я знаю, что ты бросил жену с детьми.
		shape *
			tags / \person
			title \💐 Форма поверх содержания
			descr \Предоставлять аргумент с акцентом на внешнюю привлекательность, а не на действенность или правильность.
			sample \У него очень приятный голос, он стильно одевается, фотогеничен и слаженно говорит. Ему можно доверять.
		genetics *
			tags / \reference
			title \🧬 Генетическая логическая ошибка
			descr \Считать что-либо плохим или хорошим, опираясь на его происхождение.
			sample \Эта картошка точно хуже - посмотри на этикетку, её привезли из Америки.
		diversion *
			tags / \content
			title \🕺 Отвлекающий манёвр
			descr \Предоставление нерелевантного материала к аргументу для отвлечения, чтобы подтолкнуть к другому заключению.
			sample \Сенатора не нужно штрафовать за нецелевые расходы. В конце концов, есть другие сенаторы, которые делают гораздо более плохие вещи.
		question *
			tags / \content
			title \❓ Наводящий вопрос
			descr \Задавать вопрос, который подразумевает истинность некого утверждения, которое на самом деле ложное или спорное.
			sample \Как вы считаете, поможет ли снизить заболеваемость недавно опубликованное научное доказательство действенности гомеопатии?
		ambiguity *
			tags / \content
			title \🤞 Двусмысленность
			descr \Использовать слова и фразы, которые могут быть поняты неоднозначно, чтобы ввести оппонента в заблуждение.
			sample \Я сейчас делаю вам очень выгодное предложение!
		analogy *
			tags / \logic
			title \🎭 Ложная аналогия
			descr \Создавать ошибочные аналогии либо метафоры, чтобы перекладывать построенные в них выводы на первоначальную проблему.
			sample \Капуста не понимает, что существует коза. Так же и человек, как капуста, не может понять Бога.
		contradiction *
			tags / \logic
			title \🤯 Противоречие
			descr \Отстаивать взаимоисключающие положения.
			sample \Никого нельзя убивать! Давайте повесим преступника.
		circle *
			tags / \logic
			title \🔁 Порочный круг
			descr \Наличие вывода среди причин.
			sample \Бог есть, потому что так написано в Библии. Библия истинна, потому что она Слово Божье.
		matryoshka *
			tags / \logic
			title \🤦‍♂️ Ошибка в ошибке
			descr \Настаивать на ошибочности всего высказывания только потому, что в одном из аргументов была допущена ошибка.
			sample \Катя: Необходимо питаться здоровой пищей, так как мой врач сообщил, что сейчас это очень популярно. Маша: Фастфуды более популярны и рекламируются на каждом углу, поэтому надо питаться гамбургерами.
		certitude *
			tags / \reference
			title \🤩 Аргумент к уверенности
			descr \Доказывать верность мнения лишь утверждением его верности. (Круговая аргументация) Позиционировать себя как светоча истинного знания.
			sample \Это правда потому что я уверен на 100% в своей правоте!
		episode *
			tags / \logic
			title \📝 Случай из жизни
			descr \Использовать личный опыт или известный случай в качестве аргумента в споре, особенно для опровержения статистики.
			sample \Да что вы мне рассказываете о вреде курения? Мой прадед курил по две пачки в день, и дожил до 97 лет.
		wish *
			tags / \emotion
			title \🌠 Желаемое за действительное
			descr \Мыслить желаниями: что-либо существует или является правдой лишь потому, что этого хочет утверждающий.
			sample \После смерти хорошие люди попадут в рай. Потому что я хороший и очень хочу туда попасть.
		result *
			tags / \emotion
			title \🏆 Аргумент к последствиям
			descr \Если что-то приводит к (не)благоприятным последствиям, то оно (не)верно.
			sample \Если подсудимый будет признан невиновным, то это может побудить других к совершению подобных преступлений, ибо они не будут бояться наказания!
		slip *
			tags / \logic
			title \🎿 Скользкий путь
			descr \Утверждать, что если случится событие "A", оно повлечёт за собой событие "Z" (не учитывая промежуточных), поэтому событие "A" ни в коем случае нельзя допустить.
			sample \Если мы разрешим гомосексуальным парам вступать в брак, следующее, с чем мы столкнёмся, будет заключение брака с собственными родителями, машинами и даже обезьянками.
		possible *
			tags / \logic
			title \🐘 Аргументация возможностью
			descr \Допущение, что если что-то может случиться, то оно обязательно случится.
			sample \На улице в любой момент с крайне малой вероятностью может упасть кирпич на голову. Не выйду сегодня из дома, чтобы не умереть.
		after *
			tags / \logic
			title \⏱ После, значит вследствие
			descr \Если одно событие произошло раньше другого, то первое обязательно стало причиной второго.
			sample \Петух кукарекает перед восходом Солнца, значит Солнце встаёт из-за петуха.
		proofer *
			tags / \logic
			title \🔀 Бремя доказательства
			descr \Говорить, что свою правоту должен доказывать отрицающий, но не утверждающий. (А должно быть наоборот)
			sample \Что, не можешь доказать, что невидимого розового единорога не существует? Значит он есть!
		falsifiability *
			tags / \logic
			title \🔮 Нефальсифицируемость
			descr \Высказывание, которое не может быть опровергнуто из-за того, что нет способа проверить, является оно ложным или нет.
			sample \После смерти души людей покидают их и перерождаются в новом теле, но ничего не помнят о прошлой жизни.
		subjective *
			tags / \logic
			title \👀 Ошибка наблюдателя
			descr \Делать ложный вывод из-за неизбежной субъективности наблюдателя (или слепо корректировать его под своё убеждение).
			sample \Все железнодорожные переезды постоянно закрыты, а светофоры на них горят красным светом. Потому что я всегда это наблюдаю, когда еду в поезде.
		exception *
			tags / \content
			title \🦄 Специальные требования
			descr \Внезапно менять правила игры, чтобы создать исключение из правил, и показать, что требования недопустимы.
			sample \Сергей требовал признать его экстрасенсом, но когда его способности стали проверять, они неожиданно испарились. "Для того, чтобы увидеть мой дар, необходимо сначала в него поверить", - не моргнув, объяснил произошедшее Сергей.
		crowd *
			tags / \reference
			title \👨‍👩‍👦‍👦 Мнение масс
			descr \Поддерживать что-либо лишь потому, что многие считают это верным.
			sample \Миллионы людей согласны с его позицией, значит он прав.
		galileo *
			tags / \reference
			title \🔥 Уловка Галилея
			descr \Утверждать, что идея, отвергаемая некоторым сообществом, обязательно верна.
			sample \Учёные высмеивают мой проект вечного двигателя, значит они боятся конкуренции, и это подтверждает важность моего проекта.
		celeprity *
			tags / \reference
			title \👑 Апелляция к знаменитости
			descr \Ссылаться на мнение известного лица или представителя власти вместо предоставления настоящего аргумента.
			sample \Не будет в следующем году кризиса! Сам министр экономики так сказал!
		anonymous *
			tags / \reference
			title \👽 Анонимный авторитет
			descr \Придавать информации убедительности, ссылаясь на неких неизвестных учёных или авторитетов, не называя их.
			sample \Науке давно известно, что люди с тёмными волосами умнее, чем люди со светлыми.
		complexity *
			tags / \reference
			title \⏰ Аргумент к сложности/невероятности
			descr \Считать информацию ошибочной из-за того, что она сложна для понимания (по причине личной некомпетентности в обсуждаемом вопросе).
			sample \Это невозможно, чтобы случайные мутации могли создать такой сложный организм, как у человека.
		tradition *
			tags / \reference
			title \🎄 Апелляция к традиции
			descr \Полагать что-либо верным (или лучше) ввиду своей традиционности.
			sample \Люди должны работать и зарабатывать, потому что их предки всегда это делали.
		novelty *
			tags / \reference
			title \🎁 Аргументация новизной
			descr \Предположение, что нечто лучше, поскольку оно новее.
			sample \Последняя версия этой операционной системы сделает мой компьютер быстрее и лучше.
		nature *
			tags / \reference
			title \🌳 Апелляция к природе
			descr \Доказывать, что всё, что естественно - не безобразно, и более того - полезно, оправданно, неизбежно, правильно или идеально.
			sample \Хищники пожирают друг друга - таков закон природы. То же делают и люди в обществе. Таков закон природы.
		emotion *
			tags / \emotion
			title \🤡	Апелляция к эмоциям
			descr \Вызывать к эмоциям вместо предоставления логических доводов.
			sample \В мире столько детей, которые умирают от голода, поэтому радуйся, что можешь есть манную кашу.
		binary *
			tags / \logic
			title \👣 Ложная дихотомия
			descr \Предоставлять два альтернативных результата как единственно возможные тогда как зачастую вариантов куда больше.
			sample \Или вы со мной, или вы против меня - на стороне моего врага.
		compromise *
			tags / \emotion
			title \🤝 Золотая середина
			descr \Настаивать на том, что в споре "худой мир лучше доброй войны" и принимать компромиссную позицию за истину.
			sample \Спорить о том вызывают ли прививки аутизм можно долго. Давайте сойдёмся на том, что прививки могут вызывать "немного" аутизма.
		evil *
			tags / \emotion
			title \😈 Два зла образуют добро
			descr \Утверждать, что одно зло может компенсировать другое и справедливость будет восстановлена.
			sample \Если мне когда-либо было плохо, значит так же плохо должно быть и другим.
		perfect *
			tags / \emotion
			title \✨ Перфекционистское заблуждение
			descr \Предположение, что единственным приемлемым результатом является идеальный, и отвержение всего, что работает не идеально.
			sample \Зачем нужна эта кампания против пьянства за рулём? Люди всё равно будут ездить пьяными, несмотря ни на что.
		partial *
			tags / \logic
			title \🍕 Часть-целое
			descr \Неумело сравнивать истинность утверждения для целого и для его частей.
			sample \Каждое зерно в куче почти ничего не весит. Следовательно, куча также почти ничего не весит.
		induction *
			tags / \logic
			title \🌍 Поспешное обобщение
			descr \Создание обобщённого вывода на основе маленькой выборки.
			sample \"Меня только что чуть не сбила женщина за рулём. Женщины не умеют водить.
		select *
			tags / \content
			title \👁‍🗨 Ошибка подтверждения
			descr \Выбор свидетельств в пользу своей идеи и игнорирование опровергающих свидетельств.
			sample \Американцы не летали на Луну! Посмотри сам, сколько всего не сходится на снимках - на небе нет звёзд, флаг колышется как на ветру, освещение нереалистичное - это точно всё снимали в Голливуде!
		association *
			tags / \logic
			title \💫 Ассоциативная ошибка
			descr \Утверждать, что свойства одной вещи присущи другой лишь из-за наличия у них общего качества.
			sample \Ядерное оружия, использует энергию от расщепления атома. Ядерная энергетика использует энергию от расщепления атома. Следовательно, ядерная энергетика плоха.
		typical *
			tags / \logic
			title \👨‍🦲 Презумпция типичности
			descr \Приравнивать понятие к более общей категории, для которой оно не является типичным представителем.
			sample \Аборт - это убийство, за него надо давать 10 лет тюрьмы!
		bore *
			tags / \emotion
			title \🥱 Обвинение в скучности
			descr \Обвинять аргументацию или человека в скучности без опровержения аргумента по сути.
			sample \Твои аргументы такие длинные и скучные. Ты всегда такой зануда?
		purism *
			tags / \content
			title \👓 Ни один истинный шотландец
			descr \Исключать членов класса с неудобным поведением, чтобы защитить весь класс.
			sample \Ислам мирная религия. Те, кто устраивают теракты, — ненастоящие мусульмане.
		dress *
			tags / \emotion
			title \👗 Платье короля
			descr \Навешивать негативные ярлыки на идею, утверждение, аргумент или вопрос, чтобы оппонент испугался её поддерживать, приводить аргументы или задавать неудобные вопросы, чтобы этот ярлык не перенёсся на его личность.
			sample \Евгеника поддерживалась нацистами. Ты что нацист, раз её поддерживаешь?
		sniper *
			tags / \content
			title \🎯 Ошибка техасского стрелка
			descr \Брать только сходные данные, тогда как отличные от них игнорировать.
			sample \Из истории про техасца, который сначала стреляет по амбару, а уже потом, в месте, где появилось самое большое количество пробоин, рисует мишень, заявляя о своих якобы удачных попаданиях.
		conviction *
			tags / \person
			title \😎 Осуждение уверенности
			descr \Подчёркивание, что слова оппонента являются лишь его личным мнением, а не истиной в последней инстанции, вместо опровержения приведённых им аргументов.
			sample \Господин палеонтолог, вы утверждаете, что единорогов никогда не существовало. Но это всего-лишь ваша точка зрения! Что бы вы ни говорили, но законам физики и биологии их существование не противоречит. 
